<template>
  <v-row class="m-0 p-0">
    <v-col cols="12" class="m-0 p-0">
      <v-card v-if="uc != null && area != null" class="m-0 p-0">
        <v-toolbar dense :color="hexTints(area.cor,0.95)">
          <v-btn icon dark @click="$emit('closeConteudosView')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ms-1 ps-0 fs-11pt f-lato fw-600">
            Conteúdos associados ao Tópico gerador
          </v-toolbar-title>
          <div class="ms-5 me-0 pe-0">
          </div>
        </v-toolbar>
      </v-card>
      <v-card class="p-0 pb-1 mt-2 mb-4">
        <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
          <v-container fluid class="m-0 p-0">
            <v-row dense align="center" justify="start" class="h26 m-0 p-0" v-if="uc != null">
              <v-col cols="auto" class="m-0 p-0 ps-2 pe-1">
                <v-avatar dark :color="hexTints(area.cor,0.88)" size="20" class="m-0 p-0">
                  <i v-i:duo#note#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                </v-avatar>
              </v-col>
              <v-col cols="auto" class="m-0 p-0">
                <div class="mt-0 line-height-3 f-lato fw-600 fs-8pt text-truncate">
                  Tópico gerador
                </div>
              </v-col>
              <v-col cols="auto" class="m-0 p-0 ps-2">
                <span class="fs-8pt fw-800">ID:</span>
                <span class="ms-1 fs-8pt fw-600 f-lato">{{topico.id}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card flat class="m-0 p-2">
          <p class="m-0 p-0 ps-2 f-raleway fs-10pt fw-600 line-height-5">
            {{topico.texto}}
          </p>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" class="">
      <conteudosassociados :topico="topico" :uc="uc" :area="area" :key="refresh"/>
    </v-col>
    <v-col cols="12" md="6" class="">
      <conteudosementa :topico="topico" :uc="uc" :area="area" :key="refresh"/>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "conteudosview",
  components: {
    'conteudoitem': () => import('./conteudo-item.vue'),
    'conteudosassociados': () => import('./conteudos-associados.vue'),
    'conteudosementa': () => import('./conteudos-ementa.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
    topico: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {
    topico() {
      this.refresh++;
    }
  },
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
